 

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Author: ['App', 'User'],
    Document: ['CaseDocument', 'CustomerDocument', 'ServiceDocument'],
    Node: [
      'App',
      'AppToken',
      'BusinessEntity',
      'Case',
      'CaseAction',
      'CaseActionResult',
      'CaseActionType',
      'CaseCloseReason',
      'CaseDocument',
      'CaseEvent',
      'CaseFee',
      'CaseFeePayment',
      'CountryArea',
      'Customer',
      'CustomerAddress',
      'CustomerContactEmail',
      'CustomerContactPhone',
      'CustomerDocument',
      'CustomerEvent',
      'Service',
      'ServiceDocument',
      'ServiceProvider',
      'ServiceProviderContact',
      'ServiceType',
      'User',
      'UserTeam',
    ],
  },
}
export default result
