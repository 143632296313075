import {
  ActiveTab,
  BulkAction,
  Dialog,
  Filters,
  Pagination,
  Sort,
  TabActionDialog,
} from '@common/types'
import { stringify as stringifyQs } from 'qs'
import urlJoin from 'url-join'

/**
 * User
 */
export const userSection = '/users/'

export const userListPath = '/'
export enum UserListUrlFiltersEnum {
  isActive = 'isActive',
  query = 'query',
}
export type UserListUrlFilters = Filters<UserListUrlFiltersEnum>
export type UserListUrlDialog = 'add' | 'remove' | TabActionDialog
export enum UserListUrlSortField {
  name = 'name',
  email = 'email',
  teamName = 'teamName',
}
export type UserListUrlSort = Sort<UserListUrlSortField>
export type UserListUrlQueryParams = ActiveTab &
  BulkAction &
  Dialog<UserListUrlDialog> &
  Pagination &
  UserListUrlFilters &
  UserListUrlSort

export const userListUrl = (params?: UserListUrlQueryParams) =>
  urlJoin(userSection, userListPath) + '?' + stringifyQs(params)

export const userDetailPath = (id: string) => urlJoin(userSection, id)

export type UserDetailUrlDialog =
  | 'change-password'
  | 'signature-update'
  | 'remove'
export type UserDetailUrlQueryParams = Dialog<UserDetailUrlDialog>

export const userDetailUrl = (id: string, params?: UserDetailUrlQueryParams) =>
  userDetailPath(encodeURIComponent(id)) + '?' + stringifyQs(params)

export const userDetailMePath = () => urlJoin(userSection, 'me')

export const userDetailMeUrl = (params?: UserDetailUrlQueryParams) =>
  userDetailMePath() + '?' + stringifyQs(params)
