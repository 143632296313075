import {
  ActiveTab,
  BulkAction,
  Dialog,
  Filters,
  FiltersWithMultipleValues,
  Pagination,
  Sort,
  TabActionDialog,
} from '@common/types'
import { stringify as stringifyQs } from 'qs'
import urlJoin from 'url-join'

export const businessEntitySection = '/business-entities/'

export const businessEntityListPath = '/'
export enum BusinessEntityListUrlFiltersEnum {
  query = 'query',
}

export enum BusinessEntityListUrlFiltersWithMultipleValuesEnum {
  type = 'type',
}

export type BusinessEntityListUrlFilters =
  Filters<BusinessEntityListUrlFiltersEnum> &
    FiltersWithMultipleValues<BusinessEntityListUrlFiltersWithMultipleValuesEnum>
export type BusinessEntityListUrlDialog = 'remove' | TabActionDialog
export enum BusinessEntityListUrlSortField {
  name = 'name',
  aliasName = 'aliasName',
}
export type BusinessEntityListUrlSort = Sort<BusinessEntityListUrlSortField>
export type BusinessEntityListUrlQueryParams = ActiveTab &
  BulkAction &
  Dialog<BusinessEntityListUrlDialog> &
  Pagination &
  BusinessEntityListUrlFilters &
  BusinessEntityListUrlSort

export const businessEntityListUrl = (
  params?: BusinessEntityListUrlQueryParams,
) =>
  urlJoin(businessEntitySection, businessEntityListPath) +
  '?' +
  stringifyQs(params)

export const businessEntityDetailPath = (id: string) =>
  urlJoin(businessEntitySection, id)

export type BusinessEntityDetailUrlDialog = 'remove'
export type BusinessEntityDetailUrlQueryParams =
  Dialog<BusinessEntityDetailUrlDialog>

export const businessEntityDetailUrl = (
  id: string,
  params?: BusinessEntityDetailUrlQueryParams,
) =>
  businessEntityDetailPath(encodeURIComponent(id)) + '?' + stringifyQs(params)
