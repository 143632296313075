import { MutationResult } from '@apollo/client'
import { ConfirmButtonTransitionState } from '@components/ConfirmButton'
import { IFilter } from '@components/Filter'
import { AutocompleteChoiceType } from '@components/FormAutocompleteTextField'
import { Node } from '@dolpheen/apollo'

export interface UserError {
  field: string | null
  message?: string
}

export interface DialogProps {
  open: boolean
  onClose: () => void
}

export interface ListSettings<TColumn extends string = string> {
  columns?: TColumn[]
  rowNumber: number
}

export enum ListViews {
  APP_LIST = 'APP_LIST',
  BUSINESS_ENTITY_LIST = 'BUSINESS_ENTITY_LIST',
  CASE_LIST = 'CASE_LIST',
  CASE_SETTINGS_LIST = 'CASE_SETTINGS_LIST',
  CUSTOMER_LIST = 'CUSTOMER_LIST',
  SERVICE_PROVIDER_LIST = 'SERVICE_PROVIDER_LIST',
  USER_LIST = 'USER_LIST',
  USER_TEAM_LIST = 'USER_TEAM_LIST',
}

export interface ListProps<TColumns extends string = string> {
  isDisabled: boolean
  settings?: ListSettings<TColumns>
  onUpdateListSettings?: (key: keyof ListSettings<TColumns>, value: any) => void
  onListSettingsReset?: () => void
  totalCount?: number
}

export interface SortPage<TSortKey extends string> {
  sort: Sort<TSortKey>
  onSort: (field: TSortKey, id?: string) => void
}

/**
 * @param toggle Will be use to change status of item
 * @param isChecked Returns true for ids of chosen items
 * @param selected  Number of chosen items.
 */

export interface ListActionsWithoutToolbar {
  toggle: (id: string) => void
  toggleAll: (items: Node[], selected: number) => void
  isChecked: (id: string) => boolean
  selected: number
}
export type TabListActions<TToolbars extends string> =
  ListActionsWithoutToolbar &
    Record<TToolbars, React.ReactNode | React.ReactNodeArray>
export interface ListActions extends ListActionsWithoutToolbar {
  toolbar: React.ReactNode | React.ReactNode[]
}
export interface PageListProps<TColumns extends string = string>
  extends ListProps<TColumns> {
  defaultSettings?: ListSettings<TColumns>
  onAdd: () => void
}

export interface SearchProps {
  onSearchChange: (value: string) => void
}
export interface SearchPageProps extends SearchProps {
  initialSearch: string
}
export interface FilterPageProps<TKeys extends string, TOpts extends any>
  extends FilterProps<TKeys>,
    SearchPageProps,
    TabPageProps {
  filterOpts: TOpts
}

export interface FilterProps<TKeys extends string> {
  currencySymbol?: string
  onFilterChange: (filter: IFilter<TKeys>) => void
}

export interface TabPageProps {
  currentTab: number
  tabs: string[]
  onAll: () => void
  onTabChange: (tab: number) => void
  onTabDelete: () => void
  onTabSave: () => void
}

export interface PartialMutationProviderOutput<
  TData extends Record<string, any> = object,
  TVariables extends Record<string, any> = object,
> {
  opts: MutationResult<TData> & MutationResultAdditionalProps
  mutate: (variables: TVariables) => void
}

export interface SlugNode {
  slug: string
}

export interface TagNode {
  tag: string
}

export type Pagination = Partial<{
  after: string
  before: string
}>

export type Dialog<TDialog extends string> = Partial<{
  action: TDialog
}>
export type ActiveTab<TTab extends string = string> = Partial<{
  activeTab: TTab
}>
export type Filters<TFilters extends string> = Partial<Record<TFilters, string>>
export type FiltersWithMultipleValues<TFilters extends string> = Partial<
  Record<TFilters, string[]>
>
export type FiltersAsDictWithMultipleValues<TFilters extends string> = Partial<
  Record<TFilters, Record<string, string[]>>
>
export type Search = Partial<{
  query: string
}>
export type SingleAction = Partial<{
  id: string
}>
export type Sort<TSort extends string = string> = Partial<{
  asc: boolean
  sort: TSort
}>
export type BulkAction = Partial<{
  ids: string[]
}>

export interface ReorderEvent {
  oldIndex: number
  newIndex: number
}
export type ReorderAction = (event: ReorderEvent) => void

export interface FetchMoreProps {
  isLoading: boolean
  hasMore: boolean
  onFetchMore: () => void
  totalCount?: number
}

export type TabActionDialog = 'save-search' | 'delete-search'

export interface MutationResultAdditionalProps {
  status: ConfirmButtonTransitionState
}

export type MinMax = Record<'min' | 'max', string>

export interface FilterOpts<T> {
  active: boolean
  value: T
}

export enum StatusType {
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export interface LocalizedStatus {
  localized: string
  status: StatusType
}

export type RelayToFlat<T extends { edges: Array<{ node: Node }> }> =
  ReadonlyArray<T['edges'][0]['node']>

export type RelayNode<
  T extends { edges: ReadonlyArray<{ readonly node: Node }> },
> = T['edges'][0]['node']

export interface AutocompleteFilterOpts
  extends FetchMoreProps,
    SearchPageProps {
  choices: AutocompleteChoiceType[]
  displayValues: AutocompleteChoiceType[]
}
