import {
  ActiveTab,
  BulkAction,
  Dialog,
  Filters,
  FiltersWithMultipleValues,
  Pagination,
  SingleAction,
  Sort,
  TabActionDialog,
} from '@common/types'
import { stringify as stringifyQs } from 'qs'
import urlJoin from 'url-join'

export const caseSection = '/cases/'

export const casesAllPath = 'all'

export enum CasesUrlFiltersEnum {
  createdFrom = 'createdFrom',
  createdTo = 'createdTo',
  phoneNumber = 'phoneNumber',
  emailAddress = 'emailAddress',
  title = 'title',
  query = 'query',
}

export enum CasesUrlFiltersWithMultipleValuesEnum {
  assignedTo = 'assignedTo',
  customer = 'customer',
  closeReason = 'closeReason',
  status = 'status',
  feePaymentStatus = 'feePaymentStatus',
}

export enum CasesUrlSortField {
  title = 'title',
  created = 'created',
  assignee = 'assigned',
  customer = 'customer',
}

export type CasesUrlFilters = Filters<CasesUrlFiltersEnum> &
  FiltersWithMultipleValues<CasesUrlFiltersWithMultipleValuesEnum>
export type CasesUrlDialog = 'new' | 'assign' | 'reopen' | TabActionDialog

export type CasesUrlSort = Sort<CasesUrlSortField>
export type CasesUrlQueryParams = BulkAction &
  Dialog<CasesUrlDialog> &
  CasesUrlFilters &
  CasesUrlSort &
  Pagination &
  ActiveTab

export const caseListUrl = (
  browseType: CaseListType,
  params?: CasesUrlQueryParams,
): string => {
  const caseList = urlJoin(caseSection, browseType)
  if (params === undefined) {
    return caseList
  } else {
    return urlJoin(caseList, '?' + stringifyQs(params))
  }
}

export const caseListAllUrl = (params?: CasesUrlQueryParams): string =>
  caseListUrl(casesAllPath, params)

export const casesAssignedPath = 'assigned'
export const caseListAssignedUrl = (params?: CasesUrlQueryParams): string =>
  caseListUrl(casesAssignedPath, params)

export type CaseListType = typeof casesAllPath | typeof casesAssignedPath

export const caseDetailPath = (
  browsedType: CaseListType | undefined,
  id: string,
) => urlJoin([caseSection, ...(browsedType ? [browsedType] : []), id])

export type CaseUrlDialog =
  | 'cancel'
  | 'custom-status-edit'
  | 'email-update'
  | 'phone-update'
  | 'address-update'
  | 'service-add'
  | 'service-update'
  | 'service-delete'
  | 'service-service-provider-update'
  | 'service-service-provider-contact-update'
  | 'action-add'
  | 'action-update'
  | 'action-delete'
  | 'close-done'
  | 'close-reject'
  | 'assign'
  | 'assign-self'
  | 'fee-create'
  | 'fee-update'
  | 'fee-payment-add'
  | 'fee-payment-update'
  | 'fee-payment-delete'
  | 'case-document-add'
  | 'case-document-edit'
  | 'case-document-delete'
  | 'customer-document-add'
  | 'customer-document-edit'
  | 'customer-document-delete'
  | 'reopen'
  | 'service-document-add'
  | 'service-document-edit'
  | 'service-document-delete'
  | 'document-generate-power-of-attorney'
  | 'document-generate-ptp-letter'
  | 'document-generate-invoice-for-fee'

export type CaseUrlQueryParams = Dialog<CaseUrlDialog> & SingleAction
export const caseDetailUrl = (
  browseType: CaseListType | undefined,
  id: string,
  params?: CaseUrlQueryParams,
) =>
  caseDetailPath(browseType, encodeURIComponent(id)) + '?' + stringifyQs(params)
