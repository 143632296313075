import {
  ActiveTab,
  BulkAction,
  Dialog,
  Filters,
  FiltersWithMultipleValues,
  Pagination,
  Sort,
  TabActionDialog,
} from '@common/types'
import { stringify as stringifyQs } from 'qs'
import urlJoin from 'url-join'

export const userTeamSection = '/user-teams/'

export const userTeamListPath = '/'
export enum UserTeamListUrlFiltersEnum {
  query = 'query',
}

export enum UserTeamListUrlFiltersWithMultipleValuesEnum {
  type = 'type',
}

export type UserTeamListUrlFilters = Filters<UserTeamListUrlFiltersEnum> &
  FiltersWithMultipleValues<UserTeamListUrlFiltersWithMultipleValuesEnum>
export type UserTeamListUrlDialog = 'remove' | TabActionDialog
export enum UserTeamListUrlSortField {
  name = 'name',
}
export type UserTeamListUrlSort = Sort<UserTeamListUrlSortField>
export type UserTeamListUrlQueryParams = ActiveTab &
  BulkAction &
  Dialog<UserTeamListUrlDialog> &
  Pagination &
  UserTeamListUrlFilters &
  UserTeamListUrlSort

export const userTeamListUrl = (params?: UserTeamListUrlQueryParams) =>
  urlJoin(userTeamSection, userTeamListPath) + '?' + stringifyQs(params)

export const userTeamDetailPath = (id: string) => urlJoin(userTeamSection, id)

export type UserTeamDetailUrlDialog = 'remove'
export type UserTeamDetailUrlQueryParams = Dialog<UserTeamDetailUrlDialog>

export const userTeamDetailUrl = (
  id: string,
  params?: UserTeamDetailUrlQueryParams,
) => userTeamDetailPath(encodeURIComponent(id)) + '?' + stringifyQs(params)
