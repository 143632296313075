import NotFound404 from '@dolpheen/images/NotFound404'
import { Typography } from '@mui/material'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import * as S from './styles'

type NotFoundPageProps =
  | {
      onBack?: () => void
    }
  | {
      backHref?: string
    }

const NotFoundPage: React.FC<NotFoundPageProps> = (props) => (
  <S.Root>
    <S.Container>
      <S.InnerContainer>
        <div>
          <S.Header variant="h3">
            <FormattedMessage defaultMessage="Ooops!..." />
          </S.Header>
          <S.Header variant="h4">
            <FormattedMessage defaultMessage="Something's missing" />
          </S.Header>
          <Typography>
            <FormattedMessage defaultMessage="Sorry, the page was not found" />
          </Typography>
        </div>
        {'onBack' in props || 'backHref' in props ? (
          <div>
            <S.Button
              color="primary"
              variant="contained"
              onClick={'onBack' in props ? props.onBack : undefined}
              href={'backHref' in props ? props.backHref : undefined}
            >
              <FormattedMessage
                defaultMessage="Go back to dashboard"
                description="button"
              />
            </S.Button>
          </div>
        ) : null}
      </S.InnerContainer>
      <div>
        <NotFound404 />
      </div>
    </S.Container>
  </S.Root>
)
NotFoundPage.displayName = 'NotFoundPage'
export default NotFoundPage
