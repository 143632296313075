import {
  FieldReadFunction,
  TypePolicy,
} from '@apollo/client/cache/inmemory/policies'
import { relayStylePagination } from '@apollo/client/utilities'

function relayStylePaginationOptional(
  fields: string[] = ['filter', 'sort'],
): FieldReadFunction {
  return (_, context) => {
    if (
      context.variables &&
      'USE_FETCH_MORE' in context.variables &&
      context.variables.USE_FETCH_MORE
    ) {
      return relayStylePagination(fields)
    } else {
      // Add 'after', 'before' fields to have proper pagination
      return undefined
    }
  }
}

const QueryPolicies: TypePolicy = {
  fields: {
    customers: relayStylePaginationOptional(),
    users: relayStylePaginationOptional(),
  },
}

export default QueryPolicies
